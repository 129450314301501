import { FC, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import {
  CustomerFeedbackSection,
  StartFreeSection,
  SectionButtons,
  UnlimitedLookupsSection,
} from '@/components';

import {
  GetEmailsAndDailsSection,
  HittingNumberSection,
  SuccessStoriesSection,
  UniqueFeaturesSection,
  HeadSection,
  CustomersCarouselSection,
  AccurateDataSection,
} from './components';
import { ROUTES } from '@/libs/routing';
import { useAnalytics } from '@/libs/analytics';
export const Home: FC = () => {
  const { t } = useTranslation('home');
  const { homePageViewed } = useAnalytics();

  useEffect(() => {
    homePageViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadSection />
      <CustomersCarouselSection />
      <GetEmailsAndDailsSection />
      <UnlimitedLookupsSection
        title={t('unlimitedLookup.title')}
        description={t('unlimitedLookup.description')}
        actions={<SectionButtons learmMoreHref={ROUTES.WHY_POWERLEAD} />}
      />
      <AccurateDataSection />
      <HittingNumberSection />
      <UniqueFeaturesSection />
      <SuccessStoriesSection />
      <CustomerFeedbackSection />
      <StartFreeSection title={t('startFree.title')} subtitle={t('startFree.subtitle')} />
    </>
  );
};
